import React, { Component } from "react";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import asyncComponent from "../../../helpers/AsyncFunc";
import LayoutContentWrapper from "components/utility/layoutWrapper";
import { APP_PATH } from "./paths";
// import { generateActualPath } from "lib";
import CreateServices from "containers/App/Employed/Services/CreateServices";
import CreateServicesForAdmin from "containers/App/Admin/Services/CreateServices";
import { connect } from "react-redux";
import AppProvider from "../AppProvider";

const routes = [
  {
    admin: [
      {
        path: APP_PATH.dashboard,
        component: asyncComponent(() => import("../Admin/Dashboard/Dashboard"))
      },
      {
        path: APP_PATH.self_employed_list,
        component: asyncComponent(() =>
          import("../Admin/Self-employed/SelfEmployedList")
        )
      },
      {
        path: APP_PATH.self_employed_pending_list,
        component: asyncComponent(() =>
          import("../Admin/Self-employed/SelfEmployedPendingList")
        )
      },
      {
        path: APP_PATH.self_employed_details + ":id",
        component: asyncComponent(() =>
          import("../Admin/Self-employed/Details/SelfEmployedDetails")
        )
      },
      {
        path: APP_PATH.create_customer,
        component: asyncComponent(() =>
          import("../Admin/Customer/CreateCustomer")
        )
      },
      {
        path: APP_PATH.customer_edit + ":id",
        component: asyncComponent(() =>
          import("../Admin/Customer/CreateCustomer")
        )
      },
      {
        path: APP_PATH.customer_details + ":id",
        component: asyncComponent(() =>
          import("../Admin/Customer/details/CustomerDetails")
        )
      },
      {
        path: APP_PATH.customers_list,
        component: asyncComponent(() =>
          import("../Admin/Customer/CustomersList")
        )
      },
      {
        path: APP_PATH.servicesList,
        component: asyncComponent(() =>
          import("../Admin/Services/ServicesList")
        )
      },
      {
        path: APP_PATH.create_invoice,
        component: asyncComponent(() =>
          import("../Admin/Invoice/CreateInvoiceByAdmin")
        )
      },
      {
        path: APP_PATH.invoices_list,
        component: asyncComponent(() =>
          import("../Admin/Invoice/AdminInvoicesList")
        )
      },
      {
        path: `${APP_PATH.update_invoice}:invoice_id`,
        component: asyncComponent(() =>
          import("../Admin/Invoice/CreateInvoiceByAdmin")
        )
      },
      {
        path: `${APP_PATH.invoice_details}:invoice_id`,
        component: asyncComponent(() =>
          import("../Admin/Invoice/AdminInvoiceDetails")
        )
      },
      {
        path: `${APP_PATH.earnings}`,
        component: asyncComponent(() => import("../Admin/Earnings"))
      },
      {
        path: `${APP_PATH.employeeEarnings}`,
        component: asyncComponent(() =>
          import("../Admin/Earnings/EmployeeEarnings")
        )
      },
      {
        path: `${APP_PATH.transaction_list}`,
        component: asyncComponent(() =>
          import("../Admin/Transaction/TransactionList")
        )
      },
      {
        path: `${APP_PATH.transaction_history}:employeeId`,
        component: asyncComponent(() =>
          import("../Admin/Transaction/TransactionHistory")
        )
      },
      {
        path: `${APP_PATH.register_salary}`,
        component: asyncComponent(() => import("containers/App/Admin/RegisterSalary"))
      },
      {
        path: `${APP_PATH.register_salary_se}`,
        component: asyncComponent(() =>
            import("containers/App/Admin/RegisterSalary/RegisterSalaryForSE")
        )
      },
      {
        path: `${APP_PATH.salary_details}:id`,
        component: asyncComponent(() =>
          import("containers/App/Admin/RegisterSalary/SalaryDetails")
        )
      },
      {
        path: `${APP_PATH.profile}`,
        component: asyncComponent(() => import("../Admin/Profile"))
      },
      {
        path: APP_PATH.expenditure,
        component: asyncComponent(() => import("../Admin/Expenditure"))
      },
      {
        path: APP_PATH.expenditure_create,
        component: asyncComponent(() => import("../Admin/Expenditure/CreateExpenditure"))
      },
      {
        path: `${APP_PATH.expenditure_details}:id`,
        component: asyncComponent(() => import("../Admin/Expenditure/ExpenditureDetails"))
      },
      {
        path: `${APP_PATH.document}`,
        component: asyncComponent(() => import("../Admin/Document"))
      },
      {
        path: `${APP_PATH.termsCondition}`,
        component: asyncComponent(() => import("../Admin/TermsCondition"))
      },
      {
        path: `${APP_PATH.business_type}`,
        component: asyncComponent(() => import("../Admin/BusinessType/BusinessType"))
      },
    ]
  },
  {
    employed: [
      {
        path: APP_PATH.dashboard,
        component: asyncComponent(() =>
          import("../Employed/Dashboard/Dashboard")
        )
      },
      {
        path: APP_PATH.create_customer,
        component: asyncComponent(() =>
          import("../Employed/Customer/CreateCustomer")
        )
      },
      {
        path: APP_PATH.customer_edit + ":id",
        component: asyncComponent(() =>
          import("../Employed/Customer/CreateCustomer")
        )
      },
      {
        path: APP_PATH.customer_details + ":id",
        component: asyncComponent(() =>
          import("../Employed/Customer/details/CustomerDetails")
        )
      },
      {
        path: APP_PATH.customers_list,
        component: asyncComponent(() =>
          import("../Employed/Customer/CustomersList")
        )
      },
      {
        path: APP_PATH.servicesList,
        component: asyncComponent(() =>
          import("../Employed/Services/ServicesList")
        )
      },
      {
        path: APP_PATH.invoices_list,
        component: asyncComponent(() => import("../Employed/Invoice"))
      },
      {
        path: APP_PATH.create_invoice,
        component: asyncComponent(() =>
          import("../Employed/Invoice/CreateInvoice")
        )
      },
      {
        path: `${APP_PATH.update_invoice}:invoice_id`,
        component: asyncComponent(() =>
          import("../Employed/Invoice/CreateInvoice")
        )
      },
      {
        path: `${APP_PATH.invoice_details}:invoice_id`,
        component: asyncComponent(() =>
          import("../Employed/Invoice/InvoiceDetails")
        )
      },
      {
        path: `${APP_PATH.transaction_list}`,
        component: asyncComponent(() =>
          import("../Employed/Transaction/TransactionList")
        )
      },
      {
        path: `${APP_PATH.register_salary}`,
        component: asyncComponent(() => import("../Employed/RegisterSalary"))
      },
      {
        path: `${APP_PATH.salary_details}:id`,
        component: asyncComponent(() =>
          import("containers/App/Employed/RegisterSalary/SalaryDetails")
        )
      },
      {
        path: APP_PATH.profile,
        component: asyncComponent(() => import("../Employed/Profile"))
      },
      {
        path: APP_PATH.expenditure,
        component: asyncComponent(() => import("../Employed/Expenditure"))
      },
      {
        path: `${APP_PATH.expenditure_details}:id`,
        component: asyncComponent(() => import("../Employed/Expenditure/ExpenditureDetails"))
      },
      {
        path: `${APP_PATH.document}`,
        component: asyncComponent(() => import("../Employed/Document"))
      }
    ]
  }
];

class AppRouter extends Component {
  routeFilterByUserType = type => {
    const route = routes.filter(route => route[type])[0];
    if (route) {
      // console.log(route);
      return route[type];
    }
  };

  createServices = userType => {
    if (userType === "employed") {
      return <CreateServices />;
    } else if (userType === "admin") {
      return <CreateServicesForAdmin />;
    }
  };

  render() {
    const { style, auth } = this.props;
    const from = { pathname: "/login" };
    let userType = "employed";
    if (auth.user_type === 1) {
      userType = "admin";
    } else if (auth.user_type === 2) {
      userType = "employed";
    } else {
      return <Redirect to={from} />;
    }
    if (this.props.location.pathname == "/") {
      return <Redirect to={{ pathname: APP_PATH.dashboard }} />;
    }
    return (
      <div style={style}>
        <LayoutContentWrapper>
          <AppProvider>
            <Switch>
              {this.routeFilterByUserType(userType).map(singleRoute => {
                const { path, exact, ...otherProps } = singleRoute;
                return (
                  <Route
                    exact
                    key={singleRoute.path}
                    path={`${singleRoute.path}`}
                    {...otherProps}
                  />
                );
              })}
            </Switch>
            {this.createServices(userType)}
          </AppProvider>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    auth: state.Auth
  };
};

export default withRouter(connect(mapStateToProps)(AppRouter));
