export const endpoint = {
    users: 'users/',
    auth: 'auth/token/',
    customers: 'customers/',
    services: 'services/',
    changePassword: 'change-password/',
    invoices: 'invoices/',
    salaries: 'salaries/',
    transactions: 'transactions/',
    // available_balance: 'cron/refresh_available_balance/',
    statistics: 'stat/',
    statistics_se: 'stat/se/',
    syncInvoiceStatus: 'cron/refresh-invoice-paid-status/',
    forgetPassword: 'auth/password/reset/',
    syncSupplierInvoice: 'cron/update-available-balance-for-supplier-invoices/',
    syncPaidSalary: '/cron/update-salary-paid-status-from-fortnox-vouchers/',
    expenditures: 'expenditures/',
    general_document: 'general-documents/',
    terms_and_conditions: 'terms-and-conditions/',
    business_types: 'business-types/',
    business_type_api: 'business-type-api/',
};
